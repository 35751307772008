/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { shape, string } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { parseFont } from '../../helpers/common/helper';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        maxWidth: '1400px',
        margin: '0 auto',
    },
    heading: {
        fontFamily: parseFont(theme.blockHeading?.font) || 'Crimson, Garamond, Times, serif',
        fontSize: `${theme.blockHeading?.font_size || '2.875'}rem`,
        margin: '0px 0px 18px',
        '@media only screen and (max-width: 1400.5px) ': {
            padding: '0px 10px',
        },
        '@media only screen and (max-width: 768.5px) ': {
            fontSize: '2rem',
        },
    },
}));

const BlockHeading = ({
    data,
}) => {
    if (!data) return null;
    const classes = useStyles();
    return (
        <div className={classes.mainContainer}>
            <p className={classes.heading}>{data?.heading} </p>
        </div>
    );
};

BlockHeading.propTypes = {
    data: shape({
        heading: string,
    }),
};
BlockHeading.defaultProps = {
    data: {
        heading: '',
    },
};
export default BlockHeading;
